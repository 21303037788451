import request from '@/utils/request'

// api地址
const api = {
  page: '/sys/dictdata/page',
  dictPage: '/sys/dict',

}

/**
 * 查询列表
 * @param val 参数
 * @param corpId 参数
 * @returns {Promise<Array>}
 */
export function querydictdata(val, corpId) {
  return new Promise((resolve, reject) => {
    const params = { dictCode: val, corpId: corpId }
    request.get(api.page, { params: params }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 查询列表
 * @param val 参数
 * @returns {Promise<Array>}
 */
 export function getDictPage(params) {
  return new Promise((resolve, reject) => {
    request.get(api.dictPage, { params: params }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
